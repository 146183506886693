import React, {useState} from "react";
import styled from 'styled-components';
import {HelpCircle} from "@styled-icons/boxicons-regular"
import Modal from "./modal/modal";
import ModalPortActivation from "./modal-port-activation"
import ModalHelp from "./modal-help";
import { isNumber } from "lodash";

const stringToTimestamp = (date) => {
  let d = new Date(date).toString().split("(")
  return d[0]
}


const PortsStatus = ({status, currentTimestamp, sendMessage, help, isGardenAdmin }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState('')
  const [portSelected, setPortSelected] = useState('')

   const toggleModal = (type) => {
  if (type === 'help') {
       let msg = {verb:"GET", app_id: "giob36b2", query: "help",
               params: {"help_name": 'ports-status'}
              }
    sendMessage(msg)
  }
  if (isNumber(type)) {
    setPortSelected(type)
    type = 'port'
  }
  setModalType(type)
  setIsModalOpen(!isModalOpen)
  }

  if (!Array.isArray(status)) 
  {
    status =[]
    console.log("Status was not an array: ", status)
  }
  return (
    <Container>
      <Header>
        <span>Current ports status</span>
        <HelpCircle onClick={() => toggleModal('help')} size="24" color="white" ></HelpCircle>
      </Header>
      <LedContainer>
        {status.map((p,index) => {
          return (
            isGardenAdmin
              ? <ButtonCircle key={index} status={p.status} port={p.port} 
                            onClick={() => toggleModal(p.port)} >{p.port}</ButtonCircle>
              : <ButtonCircle key={index} status={p.status} port={p.port} 
                            >{p.port}</ButtonCircle>
          )
        })}
      </LedContainer>
      <Timestamp>{stringToTimestamp(currentTimestamp)}</Timestamp>
      <Modal isOpen={isModalOpen} >
              {modalType === 'port'
                ? <ModalPortActivation  toggleModal={toggleModal} 
                                        port={portSelected} 
                                        sendMessage={sendMessage} />
                : ''
              }
              {modalType === 'help'
                ? <ModalHelp  toggleModal={toggleModal} help={help} />
                : ''
              }
      </Modal>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  background: ${props => props.theme.ports.background};
  /** Smooth transition to the bottom from a greyish to a less greyish color **/
  background-image:linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
  /** A blur effect behind the form **/
  backdrop-filter: "blur(10px)"; 
  /** Thick box shadow with a greyish background on the right, bottom, with a blurred shadow **/
  box-shadow: 10px 10px 10px rgba(30,30,30,0.5);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 400px;
  height: 165px;
  border-radius: 5px;
  border: ${props => props.theme.ports.border}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  color: ${props => props.theme.ports.header.color};
  font-size: ${props => props.theme.ports.header.size};
  margin-top: 0.5rem;
  /*border: 2px solid green;*/
`;

const Timestamp = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  width: 90%;
  color: ${props => props.theme.ports.footer.color};
  font-size: ${props => props.theme.ports.footer.size};
  /*border: 2px solid green;*/
`;

const LedContainer = styled.span`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  padding: 1rem;
`;

const ButtonCircle = styled.button`
  background: ${props => props.status ? "red" : "lightgrey"};
  height: 2.1rem;
  width: 2.1rem;
  color: ${props => props.status ? "white" : "navy"};
  /*padding: 0.8rem;*/
  border: none;
  /*cursor: none;*/
  border-radius: 50%;
  /*padding: 10px;*/
  /*border: 1px solid yellow;*/

`;

export default PortsStatus;