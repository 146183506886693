import React, {useState} from 'react';
import styled from 'styled-components';
import {HelpCircle, AddToQueue} from "@styled-icons/boxicons-regular"
import ModalAddSchedule from "./modal-add-schedule";
import ModalDetailsSchedule from "./modal-details-schedule";
import ModalHelp from "./modal-help";
import Modal from "./modal/modal";


const AvailableSchedules = ({data, detailsSchedule, isGardenAdmin, sendMessage, help}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState('')

  const [selectedSchedule, setSelectedSchedule] = useState();

  const [schedule, setSchedule] = useState(false);


  const toggleModal = (type) => {
    if (type === 'help') {
      let msg = {verb:"GET", app_id: "giob36b2", query: "help",
              params: {"help_name": 'available-schedules'}
             }
    sendMessage(msg)
    }
    setModalType(type)
    setIsModalOpen(!isModalOpen)
    }

  const rowSelected = (data) => {
    setSchedule(data)
    toggleModal('add-schedule')
  }

  const scheduleSelected = (data) => {
    let msg = {verb:"GET", app_id: "giob36b2", query: "details-schedule",
               params: {"schedule": data}
              }
    sendMessage(msg)
    setSelectedSchedule(data)
    toggleModal('details-schedule')
  }


  if (!Array.isArray(data)) 
  {
    data =[]
    console.log("Data schedules was not an array: ", data)
  }
    return (
        <Container>
            <Header><span>Available Schedules</span>
              {isGardenAdmin
                ? <AddToQueue onClick={() => toggleModal('add-new-scheduele')} size="24" color="white" />
                : '' 
              }
              <HelpCircle onClick={() => toggleModal('help')} size="24" color="white" />
            </Header>
            { data.length 
                ? <TableMarkup  titles={Object.keys(data[0])} 
                                scheduleSelected={scheduleSelected} 
                                data={data} rowSelected={rowSelected} 
                                isGardenAdmin={isGardenAdmin} />
                : <span>No one schedule available yet</span>
            }
            <Modal isOpen={isModalOpen} >
            {modalType === 'add-new-scheduele'
              ? <div>
                  <div>This is Model placeholder for Add New Schedule!</div>
                  <button onClick={() => toggleModal('')} className="close-btn">
                    Close Placeholder
                  </button>
                </div>
              : ''
            }
            {modalType === 'add-schedule'
              ? <ModalAddSchedule toggleModal={toggleModal} 
                                  schedule={schedule} sendMessage={sendMessage}/>
              : ''
            } 
            {modalType === 'details-schedule'
              ? <ModalDetailsSchedule toggleModal={toggleModal} detailsSchedule={detailsSchedule}
                                      selectedSchedule={selectedSchedule} sendMessage={sendMessage}/>
              : ''
            } 
            {modalType === 'help'
              ? <ModalHelp  toggleModal={toggleModal} help={help} />
              : ''
            }
          </Modal>
        </Container>
    )

}

const TableMarkup = ({ titles, scheduleSelected, data, rowSelected, isGardenAdmin}) => (

  <StyledTable>
    <caption>available schedules</caption>
    <colgroup>
      <col />
      <col />
      <col />
    </colgroup>
    <thead>
      <tr>
        {titles.map((title, index) => (
          <th key={index}> 
          {index===3 
            ? isGardenAdmin 
              ? title
              : ''
            : title
          } 
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}  >
          {titles.map((title, index) => (
            <td key={index} style={{'textAlign': 'right'}} >
              {index===0
                ? <span onClick={() => scheduleSelected(item[title])}>{item[title]}</span>
                : ''
              }
              {index!==0 && index !== 3
                ? <span >{item[title]}</span>
                : ''
              }
              {index===3
                ? isGardenAdmin 
                  ? <Button onClick={() => rowSelected(item[titles[0]])}>{item[title]}</Button>
                  : ''
                : ''
              }
              </td>
          ))}
        </tr>
      ))}
    </tbody>
    {/* <tfoot>
      <tr>
        {titles.map((title, index) => (
          <th key={index}>{title}</th>
        ))}
      </tr>
    </tfoot> */}
  </StyledTable>
);


const Container = styled.div`
  display: flex;
  background: ${props => props.theme.ports.background};
  /** Smooth transition to the bottom from a greyish to a less greyish color **/
  background-image:linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
  /** A blur effect behind the form **/
  backdrop-filter: "blur(10px)"; 
  /** Thick box shadow with a greyish background on the right, bottom, with a blurred shadow **/
  box-shadow: 10px 10px 10px rgba(30,30,30,0.5);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2vh;
  width: 90%;
  min-height: 160px;
  max-width: 400px;
  color: white;
  border-radius: 5px;
  border: ${props => props.theme.ports.border}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  color: ${props => props.theme.ports.header.color};
  font-size: ${props => props.theme.ports.header.size};
  margin-top: 0.5rem;
`;

const StyledTable = styled.table`
  caption-side: top;
  border: 1px solid blue;
  border-collapse: collapse;
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */

  caption-side: bottom;
  /* empty-cell: show | hide;  */
  /* empty-cell is a property of table or the cells themselves */

  /* vertical-align: baseline | sub | super | text-top | 
                text-bottom | middle | top | bottom | 
                <percentage> | <length> */

  /* tbody {
    vertical-align: top;
  }              */
  td,
  th {
    border: none;
  }
  /* td,
  th {
    border: 1px solid;
  } */

  td {
    padding: 0.25rem 1rem;
  }

  tbody tr {
    :nth-of-type(odd) {
      background-color: #efefef;
      color: black;
    }
    :nth-of-type(even) {
      background-color: rgb(176,196,222);
      color: black;
    }
  }
  thead > tr {
    background-color: darkblue;

  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "palevioletred" : "white"};
  color: ${props => props.primary ? "white" : "palevioletred"};

  font-size: 1em;
  
  padding: 0.25rem 1rem;
  border: 2px solid palevioletred;
  border-radius: 3px;
  :hover {
      background-color: palevioletred;
      color: white
    }
`;


export default AvailableSchedules;