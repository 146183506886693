import React, { useState, useEffect, useRef } from 'react';
import {  useAuth0  } from "@auth0/auth0-react";
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import { ThemeProvider } from "styled-components";
import {theme} from './components/styled-theme'
import {Page, Filler, Chatbox,  
        List, Message, Footer, Input, Button} from './components/styled-app'
import PortsStatus from './components/ports-status'
import NextActivity from './components/next-activity'
import StatsActivity from './components/stats-activity'
import AvailableSchedules from './components/available-schedules'
import AutomationCalendar from './components/automation-calendar'
import TitleCard from './components/title-card'

// variable inizialization
const APP_NAME = process.env.REACT_APP_APP_NAME 
const DEVICE_NAME = process.env.REACT_APP_DEVICE_NAME 
const TIME_ZONE = process.env.REACT_APP_TIME_ZONE
const PUBLISHING_KEY = process.env.REACT_APP_PUBLISHING_KEY 
const SUBSCRIBE_KEY = process.env.REACT_APP_SUBSCRIBE_KEY 
const REQUEST_CHANNEL = process.env.REACT_APP_PUBNUB_REQUEST_CHANNEL 
const RESPONSE_CHANNEL = process.env.REACT_APP_PUBNUB_RESPONSE_CHANNEL 

console.log("Env variables: ", APP_NAME, DEVICE_NAME)

const pubnub = new PubNub({
  publishKey: PUBLISHING_KEY,
  subscribeKey: SUBSCRIBE_KEY,
  uuid: DEVICE_NAME
});
const publishChannel = REQUEST_CHANNEL
console.log("Pubnub UUID: ", pubnub.getUUID())

const deviceType = () => {
  const userAgent = window.navigator.userAgent
  const sub = userAgent.substring(userAgent.indexOf('(')+1, userAgent.indexOf(')')).split(';')
  //return sub[2] ? sub[2]:'unknown'   20 apr 2023 caused 560 different users !!!
  return DEVICE_NAME
}


function App() {
  return (
    <PubNubProvider client={pubnub}>
      <ThemeProvider theme={theme.dark}>
        <Main />
      </ThemeProvider>
    </PubNubProvider>
  );
}

function Main() {
  const pubnub = usePubNub();
  const [channels] = useState([RESPONSE_CHANNEL]);
  const [messages, addMessage] = useState([]);
  const [message, setMessage] = useState('');
  const [appStatus, setAppStatus] = useState({})
  const [status, setStatus] = useState([])
  const [activity, setActivity] = useState([])
  const [stats, setStats] = useState([])
  const [weeks, setWeeks] = useState([])
  const [schedules, setSchedules] = useState([])
  const [detailsSchedule, setDetailsSchedule] = useState([])
  const [calendar, setCalendar] = useState([])
  const [appStatusTimestamp, setAppStatusTimestamp] = useState('')
  const [portsStatusTimestamp, setPortsStatusTimestamp] = useState('')
  const [userName, setUserName] = useState('guest')
  const [isGardenAdmin, setIsGardenAdmin] = useState(false)
  const [help, setHelp] = useState()

  const {user} = useAuth0();
  

  const statusRef = useRef({})
  statusRef.current = status

  const manageSelfCase = (msg) => {
    switch (msg?.response?._type) {
      case 'action':
        console.log("Action on port: ", msg?.response?.port, msg?.response?.value)
        setPortsStatusTimestamp(msg?.response?.current_timestamp)
        setStatus(statusRef.current.map((p) => 
          p.port === msg?.response?.port
          ? {...p, status: msg?.response?.value}
          : {...p}
        ))
        sendMessage("/next-jobs")
      break;
    default:
      return null
    }
  }
  
  const msgDecode = (msg) => {
    let list = [] 
    let question = ""
    if (typeof msg?.request === 'object') {
      question = msg?.request?.query
    } else {
      question = msg?.request
    }
    if (msg?.response?.statusCode >= 400) {return null}
    switch (question) {
      case 'initialize-now':
        setAppStatusTimestamp(msg?.response?.current_timestamp)
        setAppStatus((old) => msg?.response?.app_status)
        nextJobs(msg?.response?.next_invocation)
        setPortsStatusTimestamp(msg?.response?.current_timestamp)
        setStatus((old) => [...msg?.response?.ports_status])
        list = msg?.response?.list_schedules?.map(r => {
          return {name: r.name, num_port: r.num_port, max_sec:r.max_duration, add: "add"}
        })
        setSchedules((old) => [...list])
        setCalendar((old) => [...msg?.response?.automation_calendar])
        break;
      case 'ports-status':
        setPortsStatusTimestamp(msg?.response?.current_timestamp)
        setStatus((old) => [...msg?.response?.ports_status])
        break;
      case 'app-status':
        setAppStatusTimestamp(msg?.response?.current_timestamp)
        setAppStatus((old) => msg?.response?.app_status)
        break;
      case 'next-jobs':
        nextJobs(msg?.response?.next_invocation)
        break;
      case 'stats-activities':
        setStats(msg?.response?.stats_activities)
        setWeeks(msg?.response?.weeks_available)
        break;
      case 'insert-jobs':
        nextJobs(msg?.response?.next_invocation)
        setPortsStatusTimestamp(msg?.response?.current_timestamp)
        setStatus((old) => [...msg?.response?.ports_status])
        break;
      case 'delete-jobs':
        nextJobs(msg?.response?.next_invocation)
        setPortsStatusTimestamp(msg?.response?.current_timestamp)
        setStatus((ol) => [...msg?.response?.ports_status])
        break;
      case 'list-schedules':
        list = msg?.response?.list_schedules?.map(r => {
          return {name: r.name, num_port: r.num_port, max_sec:r.max_duration, add: "add"}
        })
        setSchedules((old) => [...list])
        break;
      case 'details-schedule':
        setDetailsSchedule((old) => [...msg?.response?.details_schedule])
        break;
      case 'automation-calendar':
        setCalendar((old) => [...msg?.response?.automation_calendar])
        break;
      case 'help':
        setHelp(msg?.response?.help[0]?.gp_content || 'no help here !!!')
        break;
      case 'self':
        return manageSelfCase(msg)
      case 'stats':
        setStats(msg?.response?.stats_activities)
        setWeeks(msg?.response?.weeks_available)
        break;
     default:
        return null
    }
  }

  const nextJobs = (data) => {
        let list = []
        list = data?.map(r => {
          let on = r.on ? r.on.substring(11) : 'now'
          let off = r.off ? r.off.substring(11) : 'just now'
          return {port: r.port, on: on, off: off }
        })
        setActivity((old) => [...list])
  }
  
  const handleMessage = event => {
    const message = event.message;
    if (typeof message === 'string' || message.hasOwnProperty('text')) {
      const text = message?.text || message;
      addMessage(messages => [...messages, text]);
    }
    if (typeof message === 'object') {
      //console.log("Msg in: ", message?.request)
      let msg = msgDecode(message)
      if (msg) {addMessage(messages => [...messages, msg])}
    }
  };

  const getRequest = (message) => {
    let commands = message.split(" ")
    if (commands[0]) {
      switch (commands[0]) {
        case '/app-status':
          return {verb: 'GET', app_id: "giob36b2", query: 'app-status'}
        case '/ports-status':
          return {verb: 'GET', app_id: "giob36b2", query: 'ports-status'}
        case '/next-jobs':
          return {verb: 'GET', app_id: "giob36b2", query: 'next-jobs'}
        case '/stats-activities':
          return {verb: 'GET', app_id: "giob36b2", query: 'stats-activities', params: {year: null}}
        case '/list-schedules':
          return {verb: 'GET', app_id: "giob36b2", query: 'list-schedules'}
        case '/automation-calendar':
          return {verb: 'GET', app_id: "giob36b2", query: 'automation-calendar'}
        case '/p':
          console.log("Request ports status: ", status.length)
        break;
        case '/n':
          console.log("Request next activity: ", activity)
          break;
        case '/a':
          console.log("Request automation calendar: ", calendar)
          break;
        case '/presence':
          setPresence("hello")
          break;
        case '/clear':
          setMessage('') 
          addMessage(messages => [...[]])
        break;          
        default:
          return null
      }
    }
  }

  const sendMessage = message => {
    let messageRequest= ""
    pubnub.setUUID(deviceType()+':'+userName)
    if (typeof message !== 'object') {
      messageRequest = getRequest(message)
    } else {
      messageRequest = message
    }
    if (messageRequest) {
      pubnub
        .publish({ channel: publishChannel, message: messageRequest })
        .then(() => console.log("Message sent:", messageRequest, pubnub.getUUID()))
        .then(() => setMessage(''));
    }
  };

  const setPresence = (mood) => {
    pubnub.setState(
      {
        state: {"mood": mood, "isTyping":false},
        channels: channels,

      },
      function (status, response) {
        if (status.isError) {
          console.log("Presence error. ",status);
        }
        else {
          console.log("Set presence: ",response);
        }
      }
    );
  }

  const findAdminRole =(user) => {
    const roles = user?.['http://garden.alycante.it/roles'] || []
    const adminRoleIndex = roles.findIndex(r => {
      return r === 'garden admin'
    })
    if (adminRoleIndex !== -1) (setIsGardenAdmin(true))
  }

  useEffect(() => {
    if (user) (setUserName(user.name))
    findAdminRole(user)
    pubnub.addListener({ message: handleMessage });
    pubnub.subscribe({ channels , withPresence: true});
    setPresence("monitoring...")
    sendMessage("/app-status")
    sendMessage("/ports-status")
    sendMessage("/next-jobs")
    sendMessage("/stats-activities")
    sendMessage("/list-schedules")
    sendMessage("/automation-calendar")
    setMessage('') 
    addMessage(messages => [...[]])
  }, [pubnub, channels, user]);

  return (
    <Page>
      <Filler />
      <TitleCard data={appStatus} 
                 currentTimestamp={appStatusTimestamp}
                 userName={userName}
                 isGardenAdmin={isGardenAdmin}
                 sendMessage={sendMessage} 
                 help={help}/>
      <PortsStatus  status={status} 
                    currentTimestamp={portsStatusTimestamp}
                    isGardenAdmin={isGardenAdmin}
                    sendMessage={sendMessage}  
                    help={help} />
      <NextActivity data={activity} 
                    isGardenAdmin={isGardenAdmin}
                    sendMessage={sendMessage} help={help}/>
      <StatsActivity data={stats} weeks={weeks}
                    isGardenAdmin={isGardenAdmin}
                    sendMessage={sendMessage} help={help}/>
      <AvailableSchedules data={schedules} 
                          detailsSchedule={detailsSchedule}
                          isGardenAdmin={isGardenAdmin} 
                          sendMessage={sendMessage}
                          help={help} />
      <AutomationCalendar data={calendar} 
                          isGardenAdmin={isGardenAdmin}
                          sendMessage={sendMessage} help={help} />
      <Filler />
    </Page>
  );
}

export default App;

