import React, {useState} from "react";
import styled from "styled-components";
import {Delete} from "@styled-icons/fluentui-system-regular"
import {HelpCircle} from "@styled-icons/boxicons-regular"
import Modal from "./modal/modal";
import ModalHelp from "./modal-help";


const NextActivity = ({ data, isGardenAdmin, sendMessage, help }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState('')

   const toggleModal = (type) => {
  if (type === 'help') {
       let msg = {verb:"GET", app_id: "giob36b2", query: "help",
               params: {"help_name": 'next-activity'}
              }
    sendMessage(msg)
  }
  setModalType(type)
  setIsModalOpen(!isModalOpen)
  }

  const deleteActivity = () => {
    let text = "Are you sure to delete all Next Activity?"
    let confirm = data.length ? window.confirm(text): ""
    if (confirm) {
      let msg = {verb:"POST", app_id: "giob36b2", query: "delete-jobs"}
      sendMessage(msg)
    }
  }

    return (
        <Container>
          <Header><span>Next activity </span>
            { data.length
              ? isGardenAdmin
                ? <Delete onClick={deleteActivity} size="26"></Delete>
                : ''
              : <span> </span>
            }
            <HelpCircle onClick={() => toggleModal('help')} size="24" color="white" ></HelpCircle>
          </Header>
          { data.length 
            ? <TableMarkup titles={Object.keys(data[0])} data={data} />
            : <span>No activity yet</span>
          }
        <Modal isOpen={isModalOpen} >
            {modalType === 'help'
              ? <ModalHelp  toggleModal={toggleModal} help={help} />
              : ''
            }
        </Modal>
        </Container>
    )
}

const TableMarkup = ({ titles, data }) => (
  <StyledTable>
    <caption>next ports activities</caption>
    <colgroup>
      <col />
      <col />
      <col />
    </colgroup>
    <thead>
      <tr>
        {titles.map((title, index) => (
          <th key={index}>{title}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          {titles.map((title, index) => (
            <td key={index}>{item[title]}</td>
          ))}
        </tr>
      ))}
    </tbody>
    {/* <tfoot>
      <tr>
        {titles.map((title, index) => (
          <th key={index}>{title}</th>
        ))}
      </tr>
    </tfoot> */}
  </StyledTable>
);

const Container = styled.div`
  display: flex;
  background: ${props => props.theme.ports.background};
  /** Smooth transition to the bottom from a greyish to a less greyish color **/
  background-image:linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
  /** A blur effect behind the form **/
  backdrop-filter: "blur(10px)"; 
  /** Thick box shadow with a greyish background on the right, bottom, with a blurred shadow **/
  box-shadow: 10px 10px 10px rgba(30,30,30,0.5);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2vh;
  width: 90%;
  min-height: 160px;
  max-width: 400px;
  color: white;
  border-radius: 5px;
  border: ${props => props.theme.ports.border}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  color: ${props => props.theme.ports.header.color};
  font-size: ${props => props.theme.ports.header.size};
  margin-top: 0.5rem;
  /*border: 2px solid green;*/
`;

const StyledTable = styled.table`
  caption-side: top;
  border: 1px solid blue;
  border-collapse: collapse;
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */

  caption-side: bottom;
  /* empty-cell: show | hide;  */
  /* empty-cell is a property of table or the cells themselves */

  /* vertical-align: baseline | sub | super | text-top | 
                text-bottom | middle | top | bottom | 
                <percentage> | <length> */

  /* tbody {
    vertical-align: top;
  }              */
  td,
  th {
    border: none;
  }
  /* td,
  th {
    border: 1px solid;
  } */

  td {
    padding: 2px 10px;
  }

  tbody tr {
    :nth-of-type(odd) {
      background-color: #efefef;
      color: black;
    }
    :nth-of-type(even) {
      background-color: rgb(176,196,222);
      color: black;
    }
    :hover {
      background-color: lightsteelblue;
    }
  }
  thead > tr {
    background-color: darkblue;

  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

export default NextActivity
