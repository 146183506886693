import React from "react";
import styled from "styled-components";


function ModalHelp({toggleModal, help}) {

  const handleCancel = async (data) => {
    toggleModal();
  };


   return (
    <Container>
      <HelpContent>
        <div dangerouslySetInnerHTML={{__html: help}} />
      </HelpContent>
      <Button onClick={handleCancel}>Close</Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2vh;
  min-height: 120px;
  max-width: 350px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  /*border: 2px solid red;*/
`;

const HelpContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 120px;
  min-width: 350px;
  /*border: 2px solid yellow;*/
`;


const Button = styled.button`
  background: palevioletred;
  color: white;
  font-size: 1rem;
  margin: 1.0rem;
  padding: 0.5rem 1.5rem;
  border: 1px solid white;
  border-radius: 10px;
`;


export default ModalHelp;
