import React, {useState} from "react";
import {  useAuth0  } from "@auth0/auth0-react";
import styled from "styled-components";
import {HelpCircle, Refresh, LogIn, LogOut, LoaderCircle} from "@styled-icons/boxicons-regular"
import Modal from "./modal/modal";
import ModalGpioMode from "./modal-gpio-mode";
import ModalActiveFrom from "./modal-active-from";
import ModalHelp from "./modal-help";

const stringToTimestamp = (date) => {
  let d = new Date(date).toString().split("(")
  return d[0]
}

const Profile = ({callback}) => {
  const {user, isAuthenticated, isLoading} = useAuth0();
  console.log("Auth0: ", isAuthenticated, isLoading)
  if (isLoading) {
    return <LoaderCircle />
  }
  
  return (
    isAuthenticated && (
        <img onClick={() => callback('logout')} 
        src={user.picture} alt={user.name} height={26} width={26}/>
    )
  );
};

const ModalLogout = ({isAuthenticated, logout, loginWithRedirect, toggleModal}) => {
  return (
      <div>
      <div>This is isProfilepOpen Modal Content!</div>
      <div>
      { isAuthenticated
        ? <LogOut  onClick={() => logout({ returnTo: window.location.origin })} size="48" color="yellow"/>
        : <LogIn onClick={() => loginWithRedirect()} size="48" color="yellow" />
      }
      </div>
      <button onClick={() => toggleModal('')} className="close-btn">
        Close Profile
      </button>
    </div>
  )
}


const TitleCard = ({ data, currentTimestamp, sendMessage, userName, isGardenAdmin, help}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState('')

  const {loginWithRedirect, isAuthenticated, logout} = useAuth0();

  const toggleModal = (type) => {
  if (type === 'help') {
       let msg = {verb:"GET", app_id: "giob36b2", query: "help",
               params: {"help_name": 'title-card'}
              }
    sendMessage(msg)
  }
  setModalType(type)
  setIsModalOpen(!isModalOpen)
  }

   return (
      <Container>
        <Header><span>Garden Automation</span>
          <Refresh onClick={() => sendMessage("/app-status")} size="26" color="white" ></Refresh>
          { isAuthenticated
            ? <Profile callback={toggleModal} />
            : <LogIn onClick={() => loginWithRedirect()} size="26" color="white" />
          }
          <HelpCircle onClick={() => toggleModal('help')} size="24" color="white" ></HelpCircle>
        </Header>
        <Row>
          <span>
            <RowLabel>User id: </RowLabel><RowText>{userName}</RowText>
          </span>
          <span>
            <RowLabel>Device id: </RowLabel><RowText>{data?.system?.gy_id}</RowText>
          </span>
        </Row>
        <Row>
          <span>
            <RowLabel>Gpio mode: </RowLabel><RowText onClick={(a) => 
                  isGardenAdmin ? toggleModal('gpio-mode') : null}>{data?.running_mode}</RowText>
          </span>
          <span>
            <RowLabel>Cpu temp: </RowLabel><RowText>{data?.env?.os?.cpuTemp}</RowText>
          </span>
        </Row>
        <Row>
          <span>
            <RowLabel>Active from: </RowLabel><RowText onClick={(a) => 
                  isGardenAdmin ? toggleModal('active-from') : null}>{stringToTimestamp(data?.system?.gy_active_from)}</RowText>
          </span>
        </Row>
        <Row>
          <span>
            <RowLabel>Last initialize: </RowLabel><RowText>{stringToTimestamp(data?.system?.gy_gpio_last_initialize)}</RowText>
          </span>
        </Row>

        <Timestamp>{stringToTimestamp(currentTimestamp)}</Timestamp>

        <Modal isOpen={isModalOpen} >
            {modalType === 'gpio-mode'
              ? <ModalGpioMode  toggleModal={toggleModal} actualMode={data?.running_mode}
                                sendMessage={sendMessage}/>
              : ''
            }
            {modalType === 'active-from'
              ? <ModalActiveFrom  toggleModal={toggleModal} activeFrom={data?.system?.gy_active_from}
                                sendMessage={sendMessage}/>
              : ''
            } 
            {modalType === 'logout'
              ? <ModalLogout  toggleModal={toggleModal} isAuthenticated={isAuthenticated}
                              logout={logout} loginWithRedirect={loginWithRedirect} />
              : ''
            } 
            {modalType === 'help'
              ? <ModalHelp  toggleModal={toggleModal} help={help} />
              : ''
            }
        </Modal>
      </Container>
  )
}



const Container = styled.div`
  display: flex;
  background: ${props => props.theme.ports.background};
  /** Smooth transition to the bottom from a greyish to a less greyish color **/
  background-image:linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
  /** A blur effect behind the form **/
  backdrop-filter: "blur(10px)"; 
  /** Thick box shadow with a greyish background on the right, bottom, with a blurred shadow **/
  box-shadow: 10px 10px 10px rgba(30,30,30,0.5);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  min-height: 120px;
  max-width: 400px;
  color: white;
  border-radius: 5px;
  border: ${props => props.theme.ports.border}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  color: ${props => props.theme.title.header.color};
  font-size: ${props => props.theme.title.header.size};
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
  /*border: 1px solid green;*/
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  font-size: ${props => props.theme.ports.header.size};

  /*border: 2px solid green;*/
`;

const RowLabel = styled.span`
  color: pink;
  font-size: 1rem;
`;

const RowText = styled.span`
  color: white;
  font-size: 0.8rem;
`;

const Timestamp = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  width: 90%;
  color: ${props => props.theme.ports.footer.color};
  font-size: ${props => props.theme.ports.footer.size};
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  /*border: 2px solid green;*/
`;


export default TitleCard
