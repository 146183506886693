import React, {useState, useRef, useEffect} from "react";
import styled from "styled-components";
import {HelpCircle, ChevronLeft, ChevronRight} from "@styled-icons/boxicons-regular" // from https://styled-icons.dev/?s=loader
import Modal from "./modal/modal";
import ModalHelp from "./modal-help";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const sprinklers = [9,10,11,12,13,14,15,16]  //to map logical ports to named sprinklers (from 1 to 8)

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      display: false,
      labels: {
        color: 'yellow',
      },
    },
    title: {
      display: true,
      text: 'Sprinklers 1 to 8, minutes x day',
      color: 'silver',
    },
  },
  scales: {
      y: {
        ticks: {
          color: 'silver',
        },
      },
      x: {
        ticks: {
          color: 'silver',
        },
      },
  },
};

// generated with https://coolors.co/palettes/trending
const palette = [
  {"name":"Light Pink","hex":"ffadad","rgb":[255,173,173],"cmyk":[0,32,32,0],"hsb":[0,32,100],"hsl":[0,100,84],"lab":[79,30,12]},
  {"name":"Deep Champagne","hex":"ffd6a5","rgb":[255,214,165],"cmyk":[0,16,35,0],"hsb":[33,35,100],"hsl":[33,100,82],"lab":[88,8,30]},
  {"name":"Lemon Yellow Crayola","hex":"fdffb6","rgb":[253,255,182],"cmyk":[1,0,29,0],"hsb":[62,29,100],"hsl":[62,100,86],"lab":[98,-12,35]},
  {"name":"Tea Green","hex":"caffbf","rgb":[202,255,191],"cmyk":[21,0,25,0],"hsb":[110,25,100],"hsl":[110,100,87],"lab":[95,-28,25]},
  {"name":"Electric Blue","hex":"9bf6ff","rgb":[155,246,255],"cmyk":[39,4,0,0],"hsb":[185,39,100],"hsl":[185,100,80],"lab":[92,-25,-13]},
  {"name":"Baby Blue Eyes","hex":"a0c4ff","rgb":[160,196,255],"cmyk":[37,23,0,0],"hsb":[217,37,100],"hsl":[217,100,81],"lab":[79,2,-33]},
  {"name":"Maximum Blue Purple","hex":"bdb2ff","rgb":[189,178,255],"cmyk":[26,30,0,0],"hsb":[249,30,100],"hsl":[249,100,85],"lab":[76,20,-37]},
  {"name":"Mauve","hex":"ffc6ff","rgb":[255,198,255],"cmyk":[0,22,0,0],"hsb":[300,22,100],"hsl":[300,100,89],"lab":[86,30,-20]},
  {"name":"Baby Powder","hex":"fffffc","rgb":[255,255,252],"cmyk":[0,0,1,0],"hsb":[60,1,100],"hsl":[60,100,99],"lab":[100,-1,1]}]

const barOpacity = 0.8

const buildDatasets = (data, year, week, palette, barOpacity, labels) => {
  let datasets = []
  data.map(row => {
    let item = {}
    if (row.year === year && row.week === week) {
      const pos = sprinklers.findIndex(s => {
        return s === Number(row.port)
      })
      if (pos !== -1){
        //console.log("Pos..: ",pos)
        item.id = pos
        item.label = 'Port ' | pos+1
        item.data = row.stream
        item.backgroundColor = `rgba(${palette[pos].rgb.join(",")},${barOpacity})`
      }
      datasets.push(item)
    }
    return ''
  }) 
  return {labels, datasets}
}

const Navigate = ({data, weeks, setDataChart}) => {
  const [weekIndex, setWeekIndex] = useState(0)

  const setIndex = (val) => {
    setWeekIndex(previous => previous+val)
    //setDataChart(buildDatasets(data, weeks[weekIndex]?.year, weeks[weekIndex]?.week,  palette, barOpacity, labels))
  }

  useEffect(() => {
    setDataChart(buildDatasets(data, weeks[weekIndex]?.year, weeks[weekIndex]?.week,  palette, barOpacity, labels))
  }, [weekIndex]);

  useEffect(() => {
    //setWeekIndex(0)
    setIndex(-weekIndex)
  }, [weeks]);

  return (
    <Weeks>
      <Week>
        {weekIndex < weeks.length-1
          ? <ChevronLeft onClick={() => setIndex(+1)} size="26" color="white" />
          : <div>&nbsp;</div>
        }
      </Week>
        <span>{weeks[weekIndex]?.label}</span>
      <Week>
        {weekIndex > 0
          ? <ChevronRight onClick={() => setIndex(-1)} size="26" color="white" />
          : <div>&nbsp;</div>
        }
      </Week>      
    </Weeks>
  )
}

const StatsActivity = ({ data, weeks, isGardenAdmin, sendMessage, help }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState('')
  const [dataChart, setDataChart] = useState({labels:labels, datasets: []})
  
  const toggleModal = (type) => {
    if (type === 'help') {
        let msg = {verb:"GET", app_id: "giob36b2", query: "help",
                params: {"help_name": 'stats-activity'}
                }
      sendMessage(msg)
    }
    setModalType(type)
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    setDataChart(buildDatasets(data, weeks[0]?.year, weeks[0]?.week, palette, barOpacity, labels))
  }, [data, weeks]);

 

  return (
    <Container>
      <Header><span>Weekly stats</span>
        { data
            ? <Navigate weeks={weeks} data={data} setDataChart={setDataChart}  size="26" color="white" />
            : ''
        }
        <HelpCircle onClick={() => toggleModal('help')} size="24" color="white" ></HelpCircle>
      </Header>
        { data
          ? <Bar options={options} data={dataChart} redraw={false}  />
          : <span>No stats available yet</span>
        }
      <Modal isOpen={isModalOpen} >
          {modalType === 'help'
            ? <ModalHelp  toggleModal={toggleModal} help={help} />
            : ''
          }
      </Modal>
    </Container>
  )
}



const Container = styled.div`
  display: flex;
  background: ${props => props.theme.ports.background};
  /** Smooth transition to the bottom from a greyish to a less greyish color **/
  background-image:linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
  /** A blur effect behind the form **/
  backdrop-filter: "blur(10px)"; 
  /** Thick box shadow with a greyish background on the right, bottom, with a blurred shadow **/
  box-shadow: 10px 10px 10px rgba(30,30,30,0.5);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1vh;
  width: 90%;
  min-height: 250px;
  max-width: 400px;
  color: white;
  border-radius: 5px;
  border: ${props => props.theme.ports.border}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  color: ${props => props.theme.ports.header.color};
  font-size: ${props => props.theme.ports.header.size};
  margin-top: 0.5rem;
  /*border: 2px solid green;*/
`;

const Weeks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  color: ${props => props.theme.ports.header.color};
  font-size: ${props => props.theme.ports.header.size};
  /*border: 2px solid green;*/
`;

const Week = styled.div`
  width: 15%;
`;

export default StatsActivity
