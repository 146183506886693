import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";


function ModalAddSchedule({toggleModal, schedule, sendMessage}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  

  const handleSubmitAdd = async (data) => {
    data["command"] = "add";
    handleSendMessage(data)
  };

  const handleSubmitNow = async (data) => {
    data.command = "now";
    handleSendMessage(data)
  };

  const handleCancel = async (data) => {
    reset();
    toggleModal();
  };

  const handleSendMessage = async (data) => {
    let start_hour = 0
    if (data.hour !== "" ) {start_hour = Number(data.hour)*3600}
    if (data.minute !== "" ) {start_hour = start_hour + Number(data.minute)*60}
    if (data.command === 'now') {start_hour = null}
    let msg = {verb:"POST", app_id: "giob36b2", query: "insert-jobs",
               params: {"delete_actual_jobs": data.flag,
               schedules: [{ name: schedule,
                             start_hour: start_hour }]
  }}
    sendMessage(msg)
    reset();
    toggleModal();
  }

   return (
    <div>
        <Header>Set starting time for "{schedule}"</Header>
        <form style={{ display: "flex", flexDirection: "column", width: 300 }}>
          <StartingHour>
            <Label>
              <LabelText mode="light">Delete all current schedules?</LabelText>
              <Checkbox {...register("flag")} type="checkbox" />
            </Label>
          </StartingHour>
          <StartingHour>
            <LabelText>Starting time</LabelText>
            <Input
              {...register("hour", { min: 0, max: 23 })}
              placeholder="hh"
            />
            <span>:</span>
            <Input
              {...register("minute", { min: 0, max: 59 })}
              placeholder="mm"
            />
          </StartingHour>
          <Errors>
            {errors.hour ? (
              <span>Hours must be between 0 and 12</span>
            ) : (
              <span></span>
            )}
            {errors.minute ? (
              <span>Minutes must be between 0 and 55</span>
            ) : (
              <span></span>
            )}
          </Errors>
          <Button onClick={handleSubmit(handleSubmitAdd)}>
            Add selected time
          </Button>
          <Button onClick={handleSubmit(handleSubmitNow)}>Add start now</Button>
          <Button onClick={handleSubmit(handleCancel)}>Cancel</Button>
        </form>
    </div>
  );
}

const Checkbox = styled.input`
  display: grid;
  margin: 0;
  color: palevioletred;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.15em solid palevioletred;
  border-radius: 0.15em;
`;

const Label = styled.label`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const LabelText = styled.span`
  font-size: 1rem;
  ${(props) => {
    switch (props.mode) {
      case "dark":
        return `
          background-color: black;
          color: white;
          ${Input}:checked + && {
            color: palevioletred;
          }
        `;
      default:
        return `
          margin-left: 1rem;
          color: white;
          ${Input}:checked + && {
            color: red;
          }
        `;
    }
  }}
`;

const StartingHour = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
`;

const Errors = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 30px;
  color: black;
  font-size: 1rem;
  padding: 5px 10px;
  margin: 0.3rem;
`;

const Button = styled.button`
  background: palevioletred;
  color: white;
  font-size: 1rem;
  margin: 1.0rem;
  padding: 0.5rem 0.5rem;
  border: 1px solid white;
  border-radius: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  color: ${props => props.theme.ports.header.color};
  font-size: ${props => props.theme.ports.header.size};
  margin-top: 0.5rem;
`;



export default ModalAddSchedule;
