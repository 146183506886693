import React from "react";
import { useForm, Controller } from "react-hook-form";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import styled from "styled-components";
import "./modal-active-from.css"


function ModalActiveFrom({toggleModal, activeFrom, sendMessage}) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm();
  

  const handleConfirm = async (data) => {
    
    handleSendMessage(data)
  };

  const handleCancel = async (data) => {
    reset();
    toggleModal();
  };

  const handleSendMessage = async (data) => {
    if(data?.active_from) {
      let msg = {verb:"POST", app_id: "giob36b2", query: "active-from",
                params: {"active_from": data?.active_from?.toISOString()}
                }
      sendMessage(msg)
    }
    reset();
    toggleModal();
  }

   return (
    <Container>
        <Header>Do you wanto to change activation from date and time?</Header>
        <form style={{ display: "flex", flexDirection: "column", width: 300, margin: "1rem" }}>
          <Row>
            <RowLabel>Date : </RowLabel>
            <RowText>
            <Controller
              control={control}
              name='active_from'
              defaultValue={new Date()}
              render={({ field }) => (
                <DateTimePicker
                  maxDetail="minute"
                  onChange={field.onChange}
                  value= {field.value}
                  className="change-default"
              />
            )}
            />
            </RowText>
          </Row>
          <Errors>
            {errors.hour ? (
              <span>Hours must be between 0 and 12</span>
            ) : (
              <span></span>
            )}
            {errors.minute ? (
              <span>Minutes must be between 0 and 55</span>
            ) : (
              <span></span>
            )}
          </Errors>
          <Button onClick={handleSubmit(handleConfirm)}>Confirm</Button>
          <Button onClick={handleSubmit(handleCancel)}>Cancel</Button>
        </form>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  min-height: 120px;
  max-width: 400px;
  color: white;
  border-radius: 5px;
`;

const Checkbox = styled.input`
  display: grid;
  margin: 0;
  color: palevioletred;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.15em solid palevioletred;
  border-radius: 0.15em;
`;

const Label = styled.label`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const LabelText = styled.span`
  ${(props) => {
    switch (props.mode) {
      case "dark":
        return `
          background-color: black;
          color: white;
          ${Input}:checked + && {
            color: palevioletred;
          }
        `;
      default:
        return `
          margin-left: 1rem;
          color: white;
          ${Input}:checked + && {
            color: red;
          }
        `;
    }
  }}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
  font-size: ${props => props.theme.ports.header.size};

  /*border: 2px solid green;*/
`;

const RowLabel = styled.span`
  color: pink;
  font-size: 1rem;
`;

const RowText = styled.span`
  color: white;
  font-size: 0.8rem;
`;

const Errors = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 30px;
  color: black;
  font-size: 1rem;
  padding: 5px 10px;
  margin: 0.3rem;
`;

const Button = styled.button`
  background: palevioletred;
  color: white;
  font-size: 1rem;
  margin: 1.0rem;
  padding: 0.5rem 0.5rem;
  border: 1px solid white;
  border-radius: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  color: ${props => props.theme.ports.header.color};
  font-size: ${props => props.theme.ports.header.size};
  margin-top: 0.5rem;
  margin-left: 1rem;
`;


export default ModalActiveFrom;
