const theme = {
  dark: {
    body: {
      background: '#d8e4bc'
    },
    title: {
      header: {
        color: 'yellow',
        size: '1.4rem'
      },
    },
    ports: {
      background: 'rgba(0,0,128,0.7)',
      border: 'none',
      header: {
        color: '#d8e4bc',
        size: '1.2rem'
      },
      lead_on: {
        background: 'red',
        color: 'white'
      },
      lead_off: {
        background: 'lightgrey',
        color: 'black'
      },
      footer: {
        color: 'lightgrey',
        size: '0.8rem'
      }
    },

  },
  light: {
      
  }

};

export  {theme}