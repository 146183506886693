import React, {useState, useEffect} from "react";
import styled from "styled-components";


function ModalDetailsSchedule({toggleModal, detailsSchedule, selectedSchedule}) {
  const [data, setData] = useState([])

  const handleCancel = async (data) => {
    setData([])
    toggleModal();
  };

  useEffect(() => {
    setData(detailsSchedule)
  }, [detailsSchedule]);

  
   return (
    <Container>
        <Header>Details for schedule "{selectedSchedule}"</Header>

        { data.length 
                ? <TableMarkup  titles={Object.keys(data[0])} 
                                data={data}  />
                : <span>No one schedule available yet</span>
            }
          <Button onClick={handleCancel}>Cancel</Button>

    </Container>
  );
}

const TableMarkup = ({ titles, data}) => (

  <StyledTable>
    <caption>schedule details</caption>
    <colgroup>
      <col />
      <col />
      <col />
    </colgroup>
    <thead>
      <tr>
        {titles.map((title, index) => (
          <th key={index}> 
            {title}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}  >
          {titles.map((title, index) => (
            <td key={index} style={{'textAlign': 'right'}} >
              {index===2 || index===3
                ? item[title].join(';')
                : item[title]
              }
              {}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
    {/* <tfoot>
      <tr>
        {titles.map((title, index) => (
          <th key={index}>{title}</th>
        ))}
      </tr>
    </tfoot> */}
  </StyledTable>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2vh;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;


const StyledTable = styled.table`
  caption-side: top;
  border: 1px solid blue;
  border-collapse: collapse;
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */
  caption-side: bottom;
  /* empty-cell: show | hide;  */
  /* empty-cell is a property of table or the cells themselves */

  /* vertical-align: baseline | sub | super | text-top | 
                text-bottom | middle | top | bottom | 
                <percentage> | <length> */

  /* tbody {
    vertical-align: top;
  }              */
  td,
  th {
    border: none;
  }

  td {
    padding: 0.25rem 1rem;
  }

  tbody tr {
    :nth-of-type(odd) {
      background-color: #efefef;
      color: black;
    }
    :nth-of-type(even) {
      background-color: rgb(176,196,222);
      color: black;
    }
  }
  thead > tr {
    background-color: darkblue;
    font-weight: none;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;



const Button = styled.button`
  background: palevioletred;
  color: white;
  font-size: 1rem;
  margin: 1.0rem;
  padding: 0.5rem 1.5rem;
  border: 1px solid white;
  border-radius: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: ${props => props.theme.ports.header.color};
  font-size: ${props => props.theme.ports.header.size};
  margin-top: 0.5rem;
`;


export default ModalDetailsSchedule;
