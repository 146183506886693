import React, {useState} from 'react';
import styled from 'styled-components';
import {HelpCircle, AddToQueue} from "@styled-icons/boxicons-regular"
import Modal from "./modal/modal";
import ModalHelp from "./modal-help";


const AutomationCalendar = ({data, isGardenAdmin, sendMessage, help}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState('')

  const toggleModal = (type) => {
  if (type === 'help') {
       let msg = {verb:"GET", app_id: "giob36b2", query: "help",
               params: {"help_name": 'automation-calendar'}
              }
    sendMessage(msg)
  }
  setModalType(type)
  setIsModalOpen(!isModalOpen)
  }

  if (!Array.isArray(data)) 
  {
    data =[]
    console.log("Data from Automation Calendar was not an array: ", data)
  }
    return (

        <Container>
          <Header><span>Automation Calendar</span>
            {isGardenAdmin
                ? <AddToQueue onClick={() => toggleModal('add-new-calendar')} size="24" color="white" />
                : '' 
            }
            <HelpCircle onClick={() => toggleModal('help')} size="24" color="white" ></HelpCircle>
          </Header>
          { data.length 
              ? <TableMarkup titles={Object.keys(data[0])} data={data} />
              : <span>No one automation calendar available yet</span>
          }
          <Modal isOpen={isModalOpen} >
            {modalType === 'add-new-calendar'
              ? <ModalAddNewCalendar  toggleModal={toggleModal} />
              : ''
            } 
            {modalType === 'help'
              ? <ModalHelp  toggleModal={toggleModal} help={help} />
              : ''
            }
          </Modal>
        </Container>
 

    )

}

const ModalAddNewCalendar = ({toggleAddModal}) => {
  return (
    <div>
    <div>This is Model placeholder for Add New Calendar!</div>
    <button onClick={() => toggleAddModal(false)} className="close-btn">
      Close Placeholder
    </button>
  </div>
  )

}

const TableMarkup = ({ titles, data}) => (
  <StyledTable>
    <caption>automation calendar</caption>
    <colgroup>
      <col />
      <col />
      <col />
    </colgroup>
    <thead>
      <tr>
        {titles.map((title, index) => (
          <th key={index}> {title} </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}  >
          {titles.map((title, index) => (
            <td key={index} style={{'textAlign': 'right'}} >
              {index===3
                ? item[title]
                : item[title]
              }
              </td>
          ))}
        </tr>
      ))}
    </tbody>
    {/* <tfoot>
      <tr>
        {titles.map((title, index) => (
          <th key={index}>{title}</th>
        ))}
      </tr>
    </tfoot> */}
  </StyledTable>
);

const Container = styled.div`
  display: flex;
  background: ${props => props.theme.ports.background};
  /** Smooth transition to the bottom from a greyish to a less greyish color **/
  background-image:linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));
  /** A blur effect behind the form **/
  backdrop-filter: "blur(10px)"; 
  /** Thick box shadow with a greyish background on the right, bottom, with a blurred shadow **/
  box-shadow: 10px 10px 10px rgba(30,30,30,0.5);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2vh;
  width: 90%;
  min-height: 160px;
  max-width: 400px;
  color: white;
  border-radius: 5px;
  border: ${props => props.theme.ports.border}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  color: ${props => props.theme.ports.header.color};
  font-size: ${props => props.theme.ports.header.size};
  margin-top: 0.5rem;
`;

const StyledTable = styled.table`
  caption-side: top;
  border: 1px solid blue;
  border-collapse: collapse;
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */

  caption-side: bottom;
  /* empty-cell: show | hide;  */
  /* empty-cell is a property of table or the cells themselves */

  /* vertical-align: baseline | sub | super | text-top | 
                text-bottom | middle | top | bottom | 
                <percentage> | <length> */

  /* tbody {
    vertical-align: top;
  }              */
  td,
  th {
    border: none;
  }
  /* td,
  th {
    border: 1px solid;
  } */

  td {
    padding: 2px 10px;
  }

  tbody tr {
    :nth-of-type(odd) {
      background-color: #efefef;
      color: black;
    }
    :nth-of-type(even) {
      background-color: rgb(176,196,222);
      color: black;
    }
  }
  thead > tr {
    background-color: darkblue;

  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "palevioletred" : "white"};
  color: ${props => props.primary ? "white" : "palevioletred"};

  font-size: 1em;
  margin: 0.5rem;
  padding: 0.25rem 1rem;
  border: 2px solid palevioletred;
  border-radius: 3px;
  :hover {
      background-color: palevioletred;
      color: white
    }
`;


export default AutomationCalendar;