import styled from 'styled-components';

const Page = styled.div`
display: flex;
flex-direction: column;
background-image: linear-gradient(to top right, #fc0345,#fc03b1,#4a03fc);
/*background: ${props => props.theme.body.background};*/
align-items: center;
justify-content: flex-start;
gap: 2vh;
min-width: 400px;
min-height: 100vh;
/*border: 2px solid red;*/
`;

const Filler = styled.div`
display: flex;
flex-direction: column;
max-width: 2vh;
/*border: 2px solid green;*/
`;

const Chatbox = styled.div`
display: flex;
background: white;
flex-direction: column;
height: 40vh;
width: 90%;
max-width: 400px;
font-size: 0.75rem;
border-radius: 5px;
/*border: 2px solid green;*/
`;

const List = styled.div`
display: flex;
align-items: flex-start;
background-color: white;
flex-direction: column;
flex-grow: 1;
overflow: auto;
padding: 5px;
font-size: '1.5rem';
border-radius: 5px;
`;

const Message = styled.div`
background-color: #eee;
border-radius: 5px;
color: #333;
font-size: 0.9rem;
margin: 5px;
padding: 2px 5px;
`;

const Footer = styled.div`
display: flex;
`;

const Input = styled.input`
flex-grow: 1;
font-size: 1rem;
padding: 10px 15px;
`;

const Button = styled.button`
font-size: 1rem;
padding: 10px 15px;
`;

export {Page, Filler, Chatbox, List, Message, Footer, Input, Button}