import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";


function ModalGpioMode({toggleModal, actualMode, sendMessage}) {
  const {
    register,
    handleSubmit,
    reset,
  } = useForm();
  

  const handleConfirm = async (data) => {
    data.next = actualMode==='real' ? 'simulate':'real'
    handleSendMessage(data)
  };

  const handleCancel = async (data) => {
    reset();
    toggleModal();
  };

  const handleSendMessage = async (data) => {

    let msg = {verb:"POST", app_id: "giob36b2", query: "gpio-mode",
               params: {"gpio_mode": data.next}
              }
    sendMessage(msg)
    reset();
    toggleModal();
  }

   return (
    <div>
        <Header>Do you wanto to change Gpio mode?</Header>
        <form style={{ display: "flex", flexDirection: "column", width: 300 }}>
          <Row>
            <RowLabel>Actual: </RowLabel>
            <RowText>
            <Input disabled
              {...register("actual")}
              defaultValue={actualMode==='real' ? 'real':'simulate'}
            />
            </RowText>
          </Row>
          <Row>
            <RowLabel>Next : </RowLabel>
            <RowText>
            <Input disabled
              {...register("next")}
              defaultValue={actualMode==='real' ? 'simulate':'real'}
            />
            </RowText>
          </Row>
          <Button onClick={handleSubmit(handleConfirm)}>Confirm</Button>
          <Button onClick={handleSubmit(handleCancel)}>Cancel</Button>
        </form>
    </div>
  );
}

const Checkbox = styled.input`
  display: grid;
  margin: 0;
  color: palevioletred;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.15em solid palevioletred;
  border-radius: 0.15em;
`;

const Label = styled.label`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const LabelText = styled.span`
  ${(props) => {
    switch (props.mode) {
      case "dark":
        return `
          background-color: black;
          color: white;
          ${Input}:checked + && {
            color: palevioletred;
          }
        `;
      default:
        return `
          margin-left: 1rem;
          color: white;
          ${Input}:checked + && {
            color: red;
          }
        `;
    }
  }}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
  font-size: ${props => props.theme.ports.header.size};

  /*border: 2px solid green;*/
`;

const RowLabel = styled.span`
  color: pink;
  font-size: 1rem;
`;

const RowText = styled.span`
  color: white;
  font-size: 0.8rem;
`;

const Errors = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 80px;
  color: black;
  font-size: 1rem;
  padding: 5px 10px;
  margin: 0.3rem;
`;

const Button = styled.button`
  background: palevioletred;
  color: white;
  font-size: 1rem;
  margin: 1.0rem;
  padding: 0.5rem 0.5rem;
  border: 1px solid white;
  border-radius: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  color: ${props => props.theme.ports.header.color};
  font-size: ${props => props.theme.ports.header.size};
  margin-top: 0.5rem;
`;


export default ModalGpioMode;
